<template>
  <div class="container">
    <nav class="nav nav-plan">
      <li class="nav-item nav-item--ref">
        <div class="card card-transparent">
          <div class="card-body">
            <ul class="list-group list-group-flush list-group--ref">
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.gestores}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.contenidosParticipantes}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.pageviews}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.espacioAlmacenamiento}}
                </span>
              </li>
              <li class="list-group-header--ref">
                <h6>{{staticTexts.appsIncluidas}}</h6>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.captacionVideo}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.concursoVideo}}
                  <HBInfoIcon>{{staticTexts.ttVideo}}</HBInfoIcon>
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.concursoFoto}}
                  <HBInfoIcon>{{staticTexts.ttFoto}}</HBInfoIcon>
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.casting}}
                  <HBInfoIcon>{{staticTexts.ttCasting}}</HBInfoIcon>
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.activacionVotaciones}}
                </span>
              </li>
              <li class="list-group-header--ref">
                <h6>{{staticTexts.caracteristicas}}</h6>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.cesionDerechos}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.accesoDescarga}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.webapp}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.basesLegales}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.stats}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.firma}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.menores}}
                </span>
              </li>
              <li class="list-group-item">
                <span class="list-group--copy---ref">
                  {{staticTexts.biblioteca}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <PlanDetail
        v-for="product in getProducts"
        :product="product"
        @toggleShow="toggleShow"
        :showDetails="showDetails"
        :key="product.code"
      ></PlanDetail>
    </nav>
    <div class="form-footer">
      <div class="container">
        <CustomPlan></CustomPlan>
      </div>
    </div>
  </div>
</template>

<script>
/* global gettext */
import { mapActions, mapGetters } from "vuex";
import PlanDetail from "@/components/PlanDetail.vue";
import HBInfoIcon from "@/components/HBInfoIcon.vue";
import CustomPlan from "@/components/CustomPlan.vue";

export default {
  components: {
    PlanDetail,
    HBInfoIcon,
    CustomPlan
  },
  data() {
    return {
      selectedPlan: null,
      showDetails: true
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    staticTexts() {
      return {
        gestores: gettext("Gestores"),
        contenidosParticipantes: gettext("Contenidos participantes/mes"),
        pageviews: gettext("Páginas vistas / mes registradas en el microsite"),
        espacioAlmacenamiento: gettext("Espacio de almacenamiento"),
        appsIncluidas: gettext("APLICACIONES INCLUIDAS"),
        captacionVideo: gettext("Captación de video y/o foto de Instagram y Twitter (@mención+ #hashtag)"),
        concursoVideo: gettext("Concurso de video*"),
        concursoFoto: gettext("Concurso de foto*"),
        casting: gettext("Casting*"),
        activacionVotaciones: gettext("Activación de votaciones y rankings"),
        caracteristicas: gettext("CARACTERÍSTICAS"),
        cesionDerechos: gettext("Cesión de derechos de los contenidos"),
        accesoDescarga: gettext("Acceso a descarga de base de datos y contenidos generados"),
        webapp: gettext("Generación de microsite con url personalizada y webapp"),
        basesLegales: gettext("Acceso a plantilla de Bases legales tipo"),
        stats: gettext("Estadísticas en tiempo real"),
        firma: gettext("Firma digital de Bases legales"),
        menores: gettext("Cesión de derechos de menores de edad con firma digital de tutores"),
        biblioteca: gettext("Acceso a biblioteca de contenidos taggeados"),
        ttVideo: gettext("La captación del contenido se puede hacer mediante subida directa, captación en Instagram y/o Twitter o enlazando video desde Tik Tok, Instagram, Youtube, Facebook y Twitter"),
        ttFoto: gettext("La captación del contenido se puede hacer mediante subida directa, captación en Instagram y/o Twitter "),
        ttCasting: gettext("La captación del contenido se puede hacer mediante subida directa, captación en Instagram y/o Twitter o enlazando video desde Tik Tok, Instagram, Youtube, Facebook y Twitter")
      }
    }
  },
  methods: {
    ...mapActions(["loadProducts"]),
    choosePlan(plan) {
      this.selectedPlan = plan;
      this.$emit("change", this.selectedPlan);
    },
    toggleShow() {
      this.showDetails = !this.showDetails;
    }
  },
  async mounted() {
    await this.loadProducts();
  }
};
</script>

<style>
.text-plan-basico {
  color: rgb(64, 123, 255);
}
.text-plan-profesional {
  color: rgb(255, 193, 0);
}
.text-plan-enterprise {
  color: rgb(126, 87, 194);
}
</style>
