<template>
  <div class="row justify-content-between align-items-center">
    <div class="col-md-4 pt-5">
      <h2 class="title text-primary">{{ staticTexts.yourPlanIsActive }}</h2>
      <h3 class="lead text-primary">{{ staticTexts.passwordAtEmail }}</h3>
      <p class="strong">{{ staticTexts.finalWords }}</p>

      <p v-html="staticTexts.rememberTryItForFree"></p>
      <p class="mt-5 d-none" >
        <small v-html="staticTexts.createManagers"></small>
      </p>
    </div>
    <div class="col-md-6">
      <img src="/static/img/saas/congrats.svg" alt="">
    </div>
  </div>
</template>

<script>
/* global gettext */
/* global dataLayer */

import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['getSelectedPlan']),
    getPrice: function () {
      return localStorage.getItem("hb_saas_price")
    },
    staticTexts: function () {
      return {
        congrats: gettext("¡ENHORABUENA!"),
        yourPlanIsActive: gettext("Ya tienes activo tu nuevo plan."),
        rememberTryItForFree: gettext("Recuerda que puedes probar nuestra plataforma 15 días de manera totalmente gratuita. Pasado este tiempo actualizaremos tu cuenta <strong>automáticamente</strong>."),
        passwordAtEmail: gettext("Hemos enviado a tu correo electrónico la contraseña de acceso."),
        createYourFirstCampaign: gettext("Crea tu primera campaña"),
        createManagers: gettext('Si no quieres crear aún la primera campaña y quieres gestionar tu plan o añadir gestores, puedes ir al <strong><a href="/maker/">panel de administración.</a></strong>'),
        finalWords: gettext("Desde ese correo podrás acceder y crear tu primera campaña."),
        welcome: gettext("Bienvenido a Hits Book"),
        goToDashboard: gettext("Ir a mi panel")
      };
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
    dataLayer.push({
      'event': 'gtm.js'
    })
    dataLayer.push({
      'Ingresos': this.getPrice
    })
    dataLayer.push({
      'Purchase': 'success'
    })
  }
};
</script>

<style>
  p.strong {
    font-weight: 800;
  }
</style>
