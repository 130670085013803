<template>
  <span>
    <a href="javascript:;" class="info-link" ref="tooltip-target" v-if="slotHasContent()" @click.prevent="toggleHelp">
      <span class="material-icons">
        {{ icon }}
      </span>
    </a>
    <b-tooltip :show.sync="showHelp" triggers="manual" :target="() => $refs['tooltip-target']" variant="light" placement="top">
      <slot />
    </b-tooltip>
  </span>
</template>

<script>
/* global gettext */
export default {
  data () {
    return {
      showHelp: false,
      tmr: null
    }
  },
  props: {
    title: {
      type: String,
      default: gettext('Campo')
    },
    icon: {
      type: String,
      default: gettext('info')
    }
  },
  methods: {
    slotHasContent (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    toggleHelp () {
      clearTimeout(this.tmr)
      this.showHelp = !this.showHelp
      if (this.showHelp) {
        this.tmr = setTimeout(this.toggleHelp.bind(this), 5000)
      }
    }
  }
};

</script>

<style lang="scss">
.alert {
  strong {
    font-weight: 800;
  }
}
.info-link {
  margin-left: 0.5em;
  &:hover, &:active, &:focus {
    opacity: 1;
  }
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}
.tooltip.b-tooltip.b-tooltip-light {
  opacity: 1;
}

</style>
