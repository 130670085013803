<template>
  <div>
    <form class="form" method action>
      <template v-if="mode === 'saas'">
        <!-- Section New SaaS SOCIAl-->
        <section class="new-header-saas" v-if="variant === 'rrss'">
          <div class="container">
            <div class="row align-content-center">
              <div class="col-lg-7">
                <h1
                  class="new-header-saas--title"
                  v-html="staticTexts.rrssClaimH1"
                ></h1>
                <p
                  class="new-header-saas--subtitle lead"
                  v-html="staticTexts.castingsClaimP1"
                ></p>
                <p>
                  <a class="text-plan--profesional" href="javascript:null" @click.prevent="setShowCustomPlanModal(true)">{{staticTexts.contestsClaimP2_1}}</a> {{staticTexts.contestsClaimP2_2}} <a class="text-warning" href="javascript:null" @click.prevent="openCalendly">{{staticTexts.contestsClaimP2_3}}</a> {{staticTexts.contestsClaimP2_4}}
                </p>
              </div>
              <div class="col-md-5">
                <figure>
                  <img src="/static/img/saas/bgheader-social.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <!-- END Section New SaaS-->
        <!-- Section New SaaS CONCURSO-->
        <section class="new-header-saas" v-if="variant === 'contests'">
          <div class="container">
            <div class="row align-content-center">
              <div class="col-lg-7">
                <h1
                  class="new-header-saas--title"
                  v-html="staticTexts.contestsClaimH1"
                ></h1>
                <p
                  class="new-header-saas--subtitle lead"
                  v-html="staticTexts.castingsClaimP1"
                ></p>
                <p>
                  <a class="text-plan--profesional" href="javascript:null" @click.prevent="setShowCustomPlanModal(true)">{{staticTexts.contestsClaimP2_1}}</a> {{staticTexts.contestsClaimP2_2}} <a class="text-warning" href="javascript:null" @click.prevent="openCalendly">{{staticTexts.contestsClaimP2_3}}</a> {{staticTexts.contestsClaimP2_4}}
                </p>
              </div>
              <div class="col-md-5">
                <figure>
                  <img src="/static/img/saas/bgheader-concurso.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <!-- END Section New SaaS CONCURSO-->
        <!-- Section New SaaS CASTING-->
        <section class="new-header-saas" v-if="variant === 'castings'">
          <div class="container">
            <div class="row align-content-center">
              <div class="col-lg-7">
                <h1
                  class="new-header-saas--title"
                  v-html="staticTexts.castingsClaimH1"
                ></h1>
                <p
                  class="new-header-saas--subtitle lead"
                  v-html="staticTexts.castingsClaimP1"
                ></p>
                <p>
                  <a class="text-plan--profesional" href="javascript:null" @click.prevent="setShowCustomPlanModal(true)">{{staticTexts.contestsClaimP2_1}}</a> {{staticTexts.contestsClaimP2_2}} <a class="text-warning" href="javascript:null" @click.prevent="openCalendly">{{staticTexts.contestsClaimP2_3}}</a> {{staticTexts.contestsClaimP2_4}}
                </p>
              </div>
              <div class="col-md-5">
                <figure>
                  <img src="/static/img/saas/bgheader-casting.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <!-- END Section New saas CASTING-->
        <!-- Section DEFAULT -->
        <template v-if="variant === 'default'">
          <div class="form-header form-header--saas text-center mb-0 container">
            <h1
              class="title text-primary animate__animated animate__fadeInDown h2 mt-2 mb-0" v-html="staticTexts.chooseYourPlan">
            </h1>
            <div class="title-mark animate__animated animate__fadeInDown">
              <span>{{ staticTexts.tryItFree }}</span>
            </div>
          </div>
        </template>
        <!-- END Section DEFAULT -->
      </template>
      <div class="form-body text-center">

        <div class="togglebutton mt-3">
          <label>
            <strong :class="{active: !period}">
              {{ staticTexts.monthlyPlan }}
              <small>{{ staticTexts.noPermanency }}</small>
            </strong>
            <input type="checkbox" :checked="period" @change="updatePeriod">
            <span class="toggle ml-3 mr-3"></span>
            <strong :class="{active: period}">
              <div class="toggle-subitem">
                {{ staticTexts.yearlyPlan }}
                <div class="badge badge-light-green">- 10%</div>
              </div>
              <!-- <span v-html="staticTexts.yearlyPlanPrice"></span> -->
              <small>({{ staticTexts.monthlyPayment }})</small>
            </strong>
          </label>
        </div>

        <PlanList></PlanList>
      </div>
      <!-- <div class="feature-product--list">
        <div class="row">
          <div class="col-lg-2">
            <h4 class="title title-mod">
              <strong>{{ staticTexts.planIncludes }}</strong>
            </h4>
          </div>
          <div class="col-lg">
            <div class="feature-product--small feature-product--small---first">
              <img
                class="hitsbook-ico"
                src="/static/img/saas/thumb-pro-01.png"
              />
              <div class="feature-product--small---text">
                <h4 class="title">
                  <small>{{ staticTexts.participationCampaigns }} </small
                  >{{ staticTexts.inSocialNetworks }}
                </h4>
                <p>{{ staticTexts.socialCapture }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div class="feature-product--small">
              <img
                class="hitsbook-ico"
                src="/static/img/saas/thumb-pro-02.png"
              />
              <div class="feature-product--small---text">
                <h4 class="title">{{ staticTexts.contests }}</h4>
                <p>{{ staticTexts.contestsDescription }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div class="feature-product--small feature-product--small---last">
              <img
                class="hitsbook-ico"
                src="/static/img/saas/thumb-pro-03.png"
              />
              <div class="feature-product--small---text">
                <h4 class="title">{{ staticTexts.castings }}</h4>
                <p>{{ staticTexts.castingsDescription }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="form-footer text-center"></div>
    </form>
  </div>
</template>

<script>
/* global gettext */
/* global MODE */
/* global dataLayer */
/* global Calendly */

import PlanList from "@/components/PlanList.vue"
import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    PlanList
  },
  data() {
    return {
      mode: "saas",
      period: true
    };
  },
  methods: {
    ...mapMutations(['setShowCustomPlanModal', 'togglePeriod']),
    openCalendly () {
      Calendly.initPopupWidget({ url: 'https://calendly.com/demo-hits-book/demo-hits-book' })
    },
    updatePeriod () {
      this.togglePeriod()
    }
  },
  computed: {
    ...mapGetters(['getPeriod']),
    variant: function() {
      return this.$route.meta.variant
    },
    staticTexts: function() {
      return {
        chooseYourPlan: gettext(
          'Prueba cualquiera de nuestros planes <span class="text-success">15 días GRATIS</span>'
        ),
        untilDate: gettext("OFERTA DE LANZAMIENTO"),
        tryItFree: gettext(
          "Sin compromiso y sin pedirte datos bancarios"
        ),
        planIncludes: gettext("Los planes incluyen:"),
        participationCampaigns: gettext("Campañas de participación"),
        inSocialNetworks: gettext("en Redes Sociales"),
        socialCapture: gettext("Captamos el contenido en video o foto que la audiencia genera en Instagram y/o Twitter"),
        contests: gettext("Concursos"),
        contestsDescription: gettext("Campañas online de video o foto donde los protagonistas son tus consumidores"),
        castings: gettext("Castings"),
        castingsDescription: gettext(
          "Crea tu casting multitudinarios online y reduce costes hasta un 90%"
        ),
        oferta: gettext('15 DÍAS GRATIS'),
        descuento: gettext('Sin compromiso y sin pedirte datos bancarios.'),
        aplicado: gettext(""),
        monthlyPlan: gettext("Plan Mensual"),
        noPermanency: gettext("SIN Permanencia"),
        yearlyPlan: gettext("Plan anual"),
        monthlyPayment: gettext("Pago mensual"),
        rrssClaimH1: gettext(
          'Lanza ya tus campañas de participación en <span class="c-instagram">Instagram</span> y/o <span class="c-twitter">Twitter</span>.'
        ),
        contestsClaimH1: gettext(
          "Crea tu primer concurso de vídeo y/o foto con el plan que más se ajuste a tus necesidades."
        ),
        contestsClaimP2_1: gettext(
          'Contacta con nosotros'
        ),
        contestsClaimP2_2: gettext(
          'si tienes dudas para elegir Plan, o'
        ),
        contestsClaimP2_3: gettext(
          'agenda un tour virtual'
        ),
        contestsClaimP2_4: gettext(
          'con uno de nuestros asesores para conocer mejor nuestra herramienta.'
        ),
        castingsClaimH1: gettext(
          "Maximiza el alcance de tus castings online, listos en menos de 24h"
        ),
        castingsClaimP1: gettext(
          'Prueba la plataforma durante 15 días totalmente <strong class="c-line c-line--succes">gratis</strong>, <strong class="c-line c-line--succes">sin compromiso</strong> y <strong class="c-line c-line--succes">sin pedirte datos bancarios</strong>.'
        ),
        castingsClaimP2: gettext(
          '<a class="text-plan--profesional" href="#">Contacta con nosotros</a> si tienes dudas para elegir Plan, o <a class="text-warning" href="#">agenda un tour virtual</a> con uno de nuestros asesores para conocer mejor nuestra herramienta.'
        )
      };
    }
  },
  watch: {
    getPeriod (newValue) {
      this.period = newValue === "year"
    }
  },
  mounted() {
    this.mode = MODE;
    this.period = this.getPeriod === "year"
    dataLayer.push({
      'event': 'gtm.js'
    })
  }
};
</script>
