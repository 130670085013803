<template>
  <li class="nav-item">
    <div class="card plan-icon--select animate__animated animate__fadeInUp" :class="{active: product.code === 'profesional'}">
      <!-- <div class="card-img">
        <img :src="'/static/img/saas/plan-' + product.code + '.svg'" alt="">
      </div> -->
      <div class="card-header">
        <div class="card-headerRow">
          <div class="card-title">
            <h3>{{product.name}}</h3>
          </div>
          <div class="card-line"></div>
          <div class="card-price">
            <span v-if="period">{{ staticTexts.monthlyPrice }}</span>
            <h4 v-html="staticTexts.totalPrice"></h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a href="javascript:;" @click.prevent="checkout()" class="btn btn-success btn-block animate__animated animate__fadeIn" v-if="mode === 'saas'">{{ staticTexts.contractPlan }}</a>
        <a href="javascript:;" @click.prevent="changePlan()" class="btn btn-success btn-block animate__animated animate__fadeIn" v-else>{{ staticTexts.changeToPlan }}</a>
      </div>
      <div class="card-footer">
        <div class="card-footerBox">
          <div class="card-footerBoxItem" :class="{collapse: !showDetails, 'mb-5': showDetails}">
            <div v-html="product.details">
            </div>
            <a href="javascript:;" @click.prevent="checkout()" class="btn btn-success animate__animated animate__fadeIn" v-if="mode === 'saas'">{{ staticTexts.contractPlan }}</a>
            <a href="javascript:;" @click.prevent="changePlan()" class="btn btn-success btn-bottom btn-block animate__animated animate__fadeIn" v-else>{{ staticTexts.changeToPlan }}</a>
          </div>
          <!-- <div class="box-saas--toggle">
            <button class="btn btn-saas--toggle btn-link text-primary" type="button" @click.prevent="$emit('toggleShow')">
              {{ toggleDetailsText }}
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <form ref="changePlanForm" action="." method="post" v-if="mode === 'change'">
      <input type="hidden" name="price" :value="getStripePrice">
      <input type="hidden" name="subscription" :value="subscriptionId">
      <input type="hidden" name="csrfmiddlewaretoken" :value="csrf_token">
    </form>
  </li>
</template>

<script>
/* global gettext, interpolate */
/* global HITSBOOK_DOMAIN, MODE, PLAN_ID, SUBSCRIPTION_ID */
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    product: {
      type: Object
    },
    showDetails: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      period: true,
      mode: 'saas',
      subscriptionId: null,
      planId: null,
      csrf_token: null,
      items: [
        {
          "price": null,
          "quantity": 1
        }
      ],
      billingAddressCollection: "auto",
      successUrl: "https://" + HITSBOOK_DOMAIN + "/saas/#/success",
      cancelUrl: "https://" + HITSBOOK_DOMAIN + "/saas/#/cancel"
    }
  },
  methods: {
    checkout () {
      localStorage.setItem("hb_saas_price", this.getStripePrice)
      // let url = "/trial/" + this.product.code + "/" + this.getInterval
      this.$router.push({ name: 'trynow', params: { 'code': this.product.code, 'interval': this.getInterval } })
    },
    changePlan () {
      this.subscriptionId = SUBSCRIPTION_ID
      this.planId = PLAN_ID
      this.$nextTick(() => {
        this.$refs.changePlanForm.submit()
      })
    }
  },
  watch: {
    getPeriod (newValue) {
      this.period = newValue === "year"
      this.items[0].price = this.getStripePrice
    }
  },
  mounted () {
    this.mode = MODE
    this.period = this.getPeriod === "year"
    this.items[0].price = this.getStripePrice
    let tokenInput = document.querySelector('[name=csrfmiddlewaretoken]')
    if (tokenInput !== null) {
      this.csrf_token = tokenInput.value
    }
  },
  computed: {
    ...mapGetters(["getPeriod"]),
    getPK () {
      return process.env.VUE_APP_PUBLISHABLE_KEY
    },
    getInterval() {
      let interval
      if (!this.period) {
        interval = "month"
      } else {
        interval = "year"
      }
      return interval
    },
    getPrice () {
      return this.product.price_set.filter((item) => item.bill_interval === this.getInterval)[0].unit_amount / 100
    },
    getTrialDays () {
      return this.product.price_set.filter((item) => item.bill_interval === this.getInterval)[0].trial_period_days
    },
    getMonthPrice () {
      let interval = "month"
      return this.product.price_set.filter((item) => item.bill_interval === interval)[0].unit_amount / 100
    },
    getYearPrice () {
      let interval = "year"
      return this.product.price_set.filter((item) => item.bill_interval === interval)[0].unit_amount * 12 / 100
    },
    getStripePrice () {
      return this.product.price_set.filter((item) => item.bill_interval === this.getInterval)[0].stripe_id
    },
    toggleDetailsText () {
      if (this.showDetails) {
        return gettext("Ocultar detalles")
      } else {
        return gettext("Ver detalles")
      }
    },
    hireButtonText () {
      let text = this.product.name
      if (this.period) {
        text = interpolate(gettext('%s anual'), [text])
      } else {
        text = interpolate(gettext('%s mensual'), [text])
      }
      return text
    },
    staticTexts: function() {
      return {
        monthlyPrice: interpolate(gettext("%s"), [this.getMonthPrice.toLocaleString('en-US')]),
        totalPrice: interpolate(gettext("<strong>%s</strong><small>$USD/mes</small>"), [this.getPrice.toLocaleString('en-US')]),
        monthlyPlan: gettext("Plan Mensual"),
        noPermanency: gettext("SIN Permanencia"),
        yearlyPlan: gettext("Plan anual"),
        yearlyPlanPrice: interpolate(gettext('$%s al año'), [this.getYearPrice.toLocaleString('en-US')]),
        monthlyPayment: gettext("Pago mensual"),
        contractPlan: gettext("Prueba GRATIS"),
        changeToPlan: interpolate(gettext("Cambiar a Plan %s"), [this.hireButtonText])
      };
    }
  }
};
</script>
