<template>
  <div class="row justify-content-between align-items-center">
    <div class="col-md-4 pt-5">
      <h6 class="mb-0">{{ staticTexts.ups }}</h6>
      <h2 class="title text-primary">{{ staticTexts.youHaveCancelled }}</h2>
      <p v-html="staticTexts.noProblem"></p>
      <router-link :to="{'name': 'choose-plan'}" class="btn btn-secondary">{{ staticTexts.redirectingIn }}</router-link>
    </div>
    <div class="col-md-6">
      <img src="/static/img/saas/congrats.svg" alt="">
    </div>
  </div>
</template>

<script>
/* global gettext, interpolate, dataLayer */

export default {
  components: {
  },
  computed: {
    staticTexts: function() {
      return {
        ups: gettext("¡UPS!"),
        youHaveCancelled: gettext("Has cancelado el proceso."),
        noProblem: gettext("No pasa nada, puedes volver a <strong>elegir plan</strong>."),
        monthlyPrice: interpolate(gettext("Redirigiendo en %s..."), [this.counter])
      };
    }
  },
  data () {
    return {
      counter: 3,
      tmr: null
    }
  },
  methods: {
    tick () {
      this.counter = this.counter - 1
      if (this.counter <= 0) {
        this.$router.push({ 'name': 'choose-plan' })
      }
    }
  },
  mounted () {
    this.counter = 3
    this.tmr = setInterval(() => { this.tick() }, 1000)
    dataLayer.push({
      'event': 'gtm.js'
    })
  },
  beforeDestroy () {
    clearInterval(this.tmr)
  }
};
</script>
