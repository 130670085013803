<template>
  <div>

    <div class="card plan-icon--select no-shadow animate__animated animate__fadeInUp pt-2">
      <div class="row align-items-center">
        <div class="col-md-4">
          <img src="/static/img/saas/plan-personalizado.svg" alt="" class="mb-2">
        </div>
        <div class="col">
          <div class="card-header">
            <div class="card-headerRow" style="justify-content: start; align-items: start;">
              <div class="card-title">
                <h3 class="text-plan--custom">{{ staticTexts.custom }}</h3>
              </div>
              <div class="card-line"></div>
            </div>
          </div>
          <div class="card-body text-left">
            <p v-html="staticTexts.noIdeaChooseThisOption"></p>
            <a href="javascript:;" @click.prevent="openModal()" class="btn btn-primary animate__animated animate__fadeIn">{{ staticTexts.requestInfo }}</a>
          </div>
        </div>
      </div>
    </div>

    <portal to="modals" v-if="getShowCustomPlanModal">
      <b-modal modal-class="modal-saas" v-model="showModal">
        <template v-slot:modal-header="{ close }">
          <div v-if="!isLoading && !isSent" class="w-100 text-center">
          <h3 class="modal-title" id="exampleModalLabel text-plan--custom">{{ staticTexts.needACustomPlan }}</h3>
          <button type="button" class="close" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
        </template>
        <template v-if="!isLoading && !isSent">
          <p class="text-center">{{ staticTexts.leaveYourPersonalInfo }}</p>
          <ValidationObserver mode="eager" ref="observer" v-slot="{ handleSubmit }">
            <template>
              <form class="form-horizontal" ref="customPlanForm" id="customPlanForm" @submit.prevent="handleSubmit(sendRequest)">
                <div class="row">
                  <div class="col-12">
                    <validation-provider mode="eager" :name="staticTexts.email" rules="required|email" v-slot="{ classes, errors }">
                      <div class="form-group has-default bmd-form-group" :class="classes">
                        <b-form-input type="email" required v-model="email" :placeholder="staticTexts.yourEmail"></b-form-input>
                        <span class="error" v-if="errors">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <validation-provider :name="staticTexts.phone" rules="required" v-slot="{ classes, errors }">
                      <div class="form-group has-default bmd-form-group" :class="classes">
                        <vue-tel-input v-model="phone" @input="phoneValidated" required :preferredCountries="['US', 'MX', 'ES']" :dynamicPlaceholder="true" @blur="phoneBlur" @focus="phoneEditing=true" invalidMsg="El telefono no es válido" wrapperClasses="form-control"></vue-tel-input>
                        <span class="error" v-if="phoneError">{{ phoneError }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-check">
                      <validation-provider rules="acceptance" v-slot="{ errors, classes }">
                        <b-form-checkbox
                          id="legal_basis_acceptance"
                          v-model="acceptation"
                          value="accepted"
                          :class="classes"
                          unchecked-value="not_accepted"
                        >
                          {{ staticTexts.privacyPolicyReaded }}
                          <div>
                            <span class="error">{{ errors[0] }}</span>
                          </div>
                        </b-form-checkbox>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </ValidationObserver>
        </template>
        <template v-if="isLoading && !isSent">
          <div class="text-center p-3 m-3">
            <b-spinner variant="primary" type="grow" :label="staticTexts.spinning"></b-spinner>
            <p class="lead mt-3">{{ staticTexts.sending }}</p>
          </div>
        </template>
        <template v-if="isSent && sentResult==='success'">
          <div class="text-center p-3 m-3">
            <span class="material-icons text-success display-1 lead animate__animated animate__zoomInDown">
            done
            </span>
            <p class="lead mt-3 text-success animate__animated animate__fadeInUp">{{ staticTexts.sentOK }}</p>
            <p class="animate__slow animate__animated animate__fadeInUp">{{ staticTexts.ourSalesTeamWillContactYou }}</p>
          </div>
        </template>
        <template v-if="isSent && sentResult!=='success'">
          <div class="text-center p-3 m-3">
            <span class="material-icons text-danger display-1 lead animate__animated animate__zoomInDown">
            error_outline
            </span>
            <p class="lead mt-3 text-danger animate__animated animate__fadeInUp">{{ staticTexts.requestCouldntBeSent }}</p>
            <p class="animate__slow animate__animated animate__fadeInUp">{{ staticTexts.retryInFewMinutes }}</p>
          </div>
        </template>
        <template v-slot:modal-footer="{ ok }">
          <button v-if="!isLoading && !isSent" form="customPlanForm" type="submit" class="btn btn-primary btn-block" @click.prevent="sendRequest()">{{ staticTexts.requestInfo }}</button>
          <button v-if="isLoading && !isSent" type="button" class="btn btn-info disabled btn-block">{{ staticTexts.sendingRequest }}</button>
          <button v-if="isSent && sentResult==='success'" type="button" @click.prevent="ok()" class="btn btn-success btn-block">{{ staticTexts.close }}</button>
          <button v-if="isSent && sentResult==='error'" type="button" @click.prevent="retry()" class="btn btn-warning btn-block">{{ staticTexts.retry }}</button>
        </template>
      </b-modal>
    </portal>
  </div>
</template>

<script>
/* global gettext */
import { BModal, BSpinner, BFormCheckbox } from 'bootstrap-vue'
import HitsbookSaaSAPI from '@/api/api.js'
import { VueTelInput } from 'vue-tel-input'
import { mapGetters, mapMutations } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueTelInput,
    BFormCheckbox,
    BSpinner,
    BModal
  },
  data () {
    return {
      email: null,
      phone: null,
      phoneEditing: false,
      phoneIsValid: false,
      phoneError: null,
      phoneNumber: null,
      acceptation: false,
      isLoading: false,
      isSent: false,
      sentResult: "error"
    }
  },
  computed: {
    ...mapGetters(['getShowCustomPlanModal']),
    showModal: {
      get () {
        return this.getShowCustomPlanModal
      },
      set (value) {
        this.setShowCustomPlanModal(value)
      }
    },
    staticTexts: function() {
      return {
        custom: gettext("Contacta con nosotros"),
        noIdeaChooseThisOption: gettext('<strong class="text-plan--custom">Si no tienes claro qué plan es el más adecuado para ti, o tienes dudas, déjanos tus datos y nos pondremos en contacto contigo.</strong>'),
        requestInfo: gettext("Contactar"),
        needACustomPlan: gettext("¿Tienes dudas?"),
        leaveYourPersonalInfo: gettext("Déjanos tus datos para ponernos en contacto contigo y ayudarte a resolver cualquier duda."),
        email: gettext("Email"),
        yourEmail: gettext("Tu email"),
        phone: gettext("Teléfono"),
        yourPhone: gettext("Tu teléfono"),
        privacyPolicyReaded: gettext("He leído y acepto la política de privacidad"),
        spinning: gettext("Spinning"),
        sending: gettext("Enviando..."),
        sentOK: gettext("Enviado correctamente"),
        ourSalesTeamWillContactYou: gettext("En breve nuestro equipo comercial te contactará."),
        requestCouldntBeSent: gettext("¡Ups! No pudimos enviar tu solicitud."),
        retryInFewMinutes: gettext("Reinténtalo de nuevo en unos minutos. Gracias."),
        sendingRequest: gettext("Enviando solicitud..."),
        close: gettext("Cerrar"),
        retry: gettext("Reintentar"),
        phoneErrorMsg: gettext("El teléfono no parece correcto. Revíselo.")
      };
    }
  },
  methods: {
    ...mapMutations(['setShowCustomPlanModal']),
    sendRequest () {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          let payload = {
            "email": this.email,
            "phone": this.phone
          }
          this.isSent = false
          this.isLoading = true
          HitsbookSaaSAPI.sendRequest(payload).then(() => {
            this.sentResult = 'success'
          }).catch(() => {
            this.sentResult = 'error'
          }).finally(() => {
            this.isSent = true
            this.isLoading = false
          })
        }
      })
    },
    phoneValidated (number, data) {
      this.phoneEditing = true
      this.phoneNumber = data
      this.phoneIsValid = data.valid
      this.phoneError = null
    },
    phoneBlur () {
      if (this.phoneIsValid) {
        this.phone = this.phoneNumber.number.international
        this.phoneError = null
      } else {
        this.phoneError = this.staticTexts.phoneErrorMsg
      }
      this.phoneEditing = false
    },
    retry () {
      this.isLoading = false
      this.isSent = false
    },
    reset () {
      this.email = null
      this.phone = null
      this.acceptation = false
    },
    openModal() {
      this.retry()
      this.reset()
      this.setShowCustomPlanModal(true)
    }
  }
};
</script>

<style>
.custom-control-label span.error {
  color: red;
}
.modal.modal-saas .modal-header svg, .modal.modal-saas .modal-header img {
  width: auto;
  margin: auto;
}
.vue-tel-input {
  border: rgba(0, 0, 0, 0.04) solid 1px;
}
</style>
